<template>
    <template v-if="!readOnly">
        <ODataGrid :data-object="dsCDE" hideGridMenu hideMultiselectColumn disableFilterRow multilineHeader style="max-height:400px; min-height:100px"
            disableDeleteConfirm newRecordsPosition="bottom"
            @beforePasteCommit="bulkInsert">
            
            <!-- STEP 1: only first 4 columns editable -->
            <template v-if="stepId && dsCDE.current.CurrentStepNo !== 2">
                <OColumn width="80" field="DocReference" headerName="Doc. Reference" editable sortable />
                <OColumn width="283" field="OriginalRequirements" headerName="Original requirements of the specification, drawings and/or associated design documents as outlined in the RFP" editable v-slot:editor="{modelValue:row}">
                    <OTextArea v-model="row.OriginalRequirements" :rows="3" auto-grow no-resize class="form-control form-control-sm" />
                </OColumn>
                <OColumn width="118" field="DeviationException" headerName="Clarification (C), Deviation (D) or Exception (E)" editable sortable v-slot:editor="{modelValue:row}">
                    <select v-model="row.DeviationException" :bind="sel=>{row.DeviationException = sel.Value}">
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="E">E</option>
                    </select>
                </OColumn>
                <OColumn width="190" field="Description" headerName="Description or reason for the Clarification, Deviation or Exception" editable v-slot:editor="{modelValue:row}">
                    <OTextArea v-model="row.Description" :rows="3" auto-grow no-resize class="form-control form-control-sm" />
                </OColumn>

                <OColumn width="70" field="NumFiles" headerName="# Files" v-slot="{ row }">
                    <button class="w-100 link" @click="showCDEFiles">{{ row.NumFiles }}</button>
                </OColumn>
                <OColumn width="70" field="AcceptReject" headerName="Accept or Reject" />
                <OColumn width="241" field="ReasonAcceptReject" headerName="Reason for acceptance or rejection" />
            </template>

            <!-- STEP 2: only last 2 columns editable -->
            <template v-else-if="stepId && dsCDE.current.CurrentStepNo === 2">
                <OColumn width="80" field="DocReference" headerName="Doc. Reference" sortable />
                <OColumn width="283" field="OriginalRequirements" headerName="Original requirements of the specification, drawings and/or associated design documents as outlined in the RFP" />
                <OColumn width="118" field="DeviationException" headerName="Clarification (C), Deviation (D) or Exception (E)" sortable />
                <OColumn width="190" field="Description" headerName="Description or reason for the Clarification, Deviation or Exception" />

                <OColumn width="70" field="NumFiles" headerName="# Files" v-slot="{ row }">
                    <button class="w-100 link" @click="showCDEFiles">{{ row.NumFiles }}</button>
                </OColumn>
                <OColumn width="70" field="AcceptReject" headerName="Accept or Reject" editable v-slot:editor="{modelValue:row}">
                    <select v-model="row.AcceptReject" :bind="sel=>{row.AcceptReject = sel.Value}">
                        <option value="Accepted">Accepted</option>
                        <option value="Rejected">Rejected</option>
                    </select>
                </OColumn>
                <OColumn width="241" field="ReasonAcceptReject" headerName="Reason for acceptance or rejection" editable v-slot:editor="{modelValue:row}">
                    <OTextArea v-model="row.ReasonAcceptReject" :rows="3" auto-grow no-resize class="form-control form-control-sm" />
                </OColumn> 
            </template>

            <!-- <OColumn v-if="dsCDE.current.CanAcceptReject" field="AcceptRejectDate" class="text-end" headerName="Accept or Reject Date" width="80"></OColumn> -->
            <!-- <OColumn v-if="dsCDE.current.CanAcceptReject" field="AcceptRejectBy" class="text-end" headerName="Accept or Reject By" :editable="false" width="80"></OColumn> -->
        </ODataGrid>
    </template>

    <!-- <template v-if="stepId"> -->
    <!-- <template v-else-if="stepId && readOnly"> -->
    <template v-else-if="readOnly">
        <table class="table small">
            <colgroup>
                <col width="90" />
                <col width="320" />
                <col width="120" />
                <col width="195" />
                <col width="60" />
                <col width="70" />
                <col />
            </colgroup>
            <thead>
                <tr>
                    <th>Doc. Reference</th>
                    <th>Original requirements of the specification, drawings and/or associated design documents as outlined in the RFP</th>
                    <th>Clarification (C), Deviation (D) or Exception (E)</th>
                    <th>Description or reason for the Clarification, Deviation or Exception</th>
                    <th># Files</th>
                    <th>Accept or Reject</th>
                    <th>Reason for acceptance or rejection</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="row in dsCDE.data">
                    <tr>
                        <td :class="{ 'changed': row.ChangedFields?.indexOf('[DocReference]') >= 0 }">{{ row.DocReference }}</td>
                        <td :class="{ 'changed': row.ChangedFields?.indexOf('[OriginalRequirements]') >= 0 }">{{ row.OriginalRequirements }}</td>
                        <td :class="{ 'changed': row.ChangedFields?.indexOf('[DeviationException]') >= 0 }">{{ row.DeviationException }}</td>
                        <td :class="{ 'changed': row.ChangedFields?.indexOf('[Description]') >= 0 }">{{ row.Description }}</td>
                        <td :class="{ 'changed': row.ChangedFields?.indexOf('[NumFiles]') >= 0 }"><button class="w-100 link" @click="showCDEFiles(row.PrimKey)">{{ row.NumFiles }}</button></td>
                        <td :class="{ 'changed': row.ChangedFields?.indexOf('[AcceptReject]') >= 0 }">{{ row.AcceptReject }}</td>
                        <td :class="{ 'changed': row.ChangedFields?.indexOf('[ReasonAcceptReject]') >= 0 }">{{ row.ReasonAcceptReject }}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </template>

    <template>
        <OModal name="dlgCDEFiles" ref="refCDEFiles" @hidden="refreshCDE">
            <div class="modal-dialog" style="min-width: 665px;" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('Files') }}</h5>
                        <button class="btn btn-sm btn-primary" style="margin-left: 15px;">
                            <OFileUploadButton :dataObject="dsCDEFiles" :row="row">
                                <i class="bi bi-upload" /> {{ $t('Upload') }}
                            </OFileUploadButton>
                        </button>
                        
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" :style="[isMobile ? {'max-height': '100vh'} : {'max-height': '70vh', 'overflow': 'auto'}]">
                        <ODataGrid :dataObject="dsCDEFiles" hideGridMenu hideMultiselectColumn hideSystemColumn>
                            <OColumn width="35" field="PrimKey" :headerName="$t('')" class="text-center" v-slot="{ row }">
                                <a :href="`/api/file/download/atbv_MSFT_CDE_DeviationAndExceptionsFiles/${row.PrimKey}/${encodeURIComponent(row.FileName)}`"><i class="bi bi-download" /></a>
                                <!-- target="_blank" -->
                            </OColumn>
                            <OColumn width="400" field="FileName" :headerName="$t('File Name')" editable sortable />
                            <!-- <OColumn field="ID"/> -->
                            <OColumn width="100" field="FileSize" :headerName="$t('File Size')" sortable v-slot="{ row }">
                                {{ $formatFileSize(row.FileSize) }}
                            </OColumn>
                            <!-- <OColumn field="FileRef" /> -->
                        </ODataGrid>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-secondary" data-bs-dismiss="modal" :title="$t('Close dialog')">{{ $t('Close') }}</button>
                    </div>
                </div>
            </div>
        </OModal>
    </template>
</template>

<style>
    select > option {
        padding: 5px 10px 5px 10px;
    }

    button.link {
        color: blue;
        cursor: pointer;
        border: 0;
        background-color: transparent;
    }

    td {
        white-space: break-spaces;
    }

    .changed {
        background-color: lightyellow !important;
    }
</style>

<script setup>
    import { ref, onMounted, defineProps } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { OFileUploadButton } from 'o365-fileupload';
    
    const refCDEFiles = ref(null);

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly: Boolean,
    });

    onMounted(() => {
        dsCDE.load();
    });

    const dsCDE = getOrCreateDataObject(
    {
        "id": "dsCDE" + props.stepId,
        "viewName": "aviw_MSFT_CDE_DeviationAndExceptions",
        "uniqueTable": "atbv_MSFT_CDE_DeviationAndExceptions",
        "distinctRows": false,
        "distinctRows": false,
        "allowUpdate":  true,
        "allowInsert":  true,
        "allowDelete":  true,
        "appendData": true,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "Item_ID"},
            {"name": "Step_ID"},
            {"name": "ID"},
            {"name": "DocReference","sortOrder":1,"sortDirection":"asc"},
            {"name": "OriginalRequirements"},
            {"name": "DeviationException"},
            {"name": "Description"},
            {"name": "AcceptReject"},
            {"name": "ReasonAcceptReject"},
            // {"name": "AcceptRejectDate"},
            // {"name": "AcceptRejectBy"},
            {"name": "PrimKey"},
            {"name": "CurrentStepNo"},
            {"name": "NumFiles"},
            {"name": "ChangedFields"}
        ],
        "clientSideHandler": false,
        "maxRecords": 50,
        "dynamicLoading": false,
        "whereClause": `Step_ID = ${props.stepId}`,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    dsCDE.on("BeforeSave", (pRow) => {
        pRow.values["Item_ID"] = props.itemId;
        pRow.values["Step_ID"] = props.stepId;
    });

    function bulkInsert(pRows) {
        pRows.forEach(pRow => {
            pRow.Item_ID = props.itemId;
            pRow.Step_ID = props.stepId;
        });
    }

    function refreshCDE() {
        dsCDE.refreshRow();
    }

    const dsCDEFiles = getOrCreateDataObject(
    {
        "id": "dsCDEFiles" + props.stepId,
        "viewName": "atbv_MSFT_CDE_DeviationAndExceptionsFiles",
        "uniqueTable": "atbv_MSFT_CDE_DeviationAndExceptionsFiles",
        "distinctRows": false,
        "distinctRows": false,
        "allowUpdate":  true,
        "allowInsert":  true,
        "allowDelete":  true,
        "appendData": true,
        "selectFirstRowOnLoad": true,
        "fields": [
            {"name": "PrimKey"},
            {"name": "FileName"},
            {"name": "FileSize"},
            {"name": "CDE_ID"},
        ],
        "clientSideHandler": false,
        "maxRecords": 50,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false,
        "masterDataObject_ID": "dsCDE" + props.stepId,
        "masterDetailDefinition": [
            {"detailField": "CDE_ID", "masterField": "ID", "operator": "equals"}
        ],
    });

    function showCDEFiles(pPrimKey) {
        if (typeof pPrimKey === "string") { dsCDE.setCurrentIndexByPrimKey(pPrimKey); }

        refCDEFiles.value.show();        
    }
</script>